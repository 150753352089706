import React from "react";
import Link from 'next/link'
import Image from "next/image";
// import { useUser } from '@auth0/nextjs-auth0/client';
import {  useEffect, useState } from 'react'
import { encode } from '../../utils/encrypt';
import { UserAtom } from '../Store/States/state';
import { useAtom } from 'jotai';

function Footer() {
  const [UserData, setUserData] = useAtom(UserAtom)
  const [auth, setauth] = useState(false)

  useEffect(() => {
    if(UserData){
      setauth(true)
    }
  }, []);
  return (
    <>
      <footer className="w-full max-w-full text-white bg-black">
        <div className="flex flex-col justify-center w-11/12 py-4 mx-auto max-w-screen-2xl lg:py-8">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-5">
              <Link href="/" className="footerLogo">
                <Image
                  priority={true}
                  className="w-48 h-auto md:w-48"
                  width={2000}
                  height={2000}
                  src={process.env.IMG_CDN+'png/delve-logo.png'}
                  alt="DelveInsight"
                />
              </Link>
              <p className="pb-2 text-sm leading-5">DelveInsight is a leading healthcare-focused market research and consulting firm that provides clients with high-quality market intelligence and analysis to support informed business decisions. With a team of experienced industry experts and a deep understanding of the life sciences and healthcare sectors, we offer customized research solutions and insights to clients across the globe. Connect with us to get high-quality, accurate, and real-time intelligence to stay ahead of the growth curve.</p>
            </div>
            <div className="col-span-12 md:col-span-7 md:pl-10 lg:pl-20">
              <h3 className="pb-6 text-lg font-semibold underline uppercase">Useful Links</h3>
              <div className="flex justify-between">
                  <ul className="w-full text-sm lg:text-md">
                    <li>
                      <Link href="/">
                        Home <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/about-us"}>
                        About Us <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/contact-us"}>
                        Contact Us <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/pharmdelve"}>
                        PharmDelve <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/careers"}>
                        Careers <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      {(auth) ?
                        <Link href={process.env.APP_URL+"/logout"}>
                          Logout <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                        </Link>
                        :
                        <Link href={process.env.APP_URL+"/login"}>
                          Login <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                        </Link>
                      }
                    </li>
                  </ul>
                  <ul className="w-full text-sm lg:text-md">
                    <li>
                      <a href="/blog/" target="_blank">
                        Blog <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </a>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/events"}>
                        Events <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/whitepaper-newsletter"}>
                        Newsletter <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/report-store.php"}>
                        Report Store <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/consulting/competitive-intelligence-services"}>
                        Competitive Intelligence <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                  </ul>
                  <ul className="w-full text-sm lg:text-md">
                    <li>
                      <Link href={process.env.APP_URL+"/sitemap"}>
                        Sitemap <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/case-study"}>
                        Case Study <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/press-release"}>
                        Press Release <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/market-research"}>
                        Market Research <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href={process.env.APP_URL+"/consulting"}>
                        Business Consulting <i className="opacity-0 fa fa-angle-right before:pl-1 group-hover:opacity-100"></i>
                      </Link>
                    </li>
                  </ul>
              </div>
              <div className="flex items-center py-2">
                <h3 className="uppercase"> We accept <span className="we-accept">:</span> </h3>
                <div className="flex justify-between">
                  <Image priority={false} className="h-6 px-2 w-14 md:w-20 md:h-10"  width={2000} height={2000} src={process.env.IMG_CDN+'img/png/visa.png'} alt="Visa" />
                  <Image priority={false} className="h-6 px-2 w-14 md:w-20 md:h-10"  width={2000} height={2000} src={process.env.IMG_CDN+'img/png/discover.png'} alt="Discover" />
                  <Image priority={false} className="h-6 px-2 w-14 md:w-20 md:h-10"  width={2000} height={2000} src={process.env.IMG_CDN+'img/png/mastercard.png'} alt="MasterCard" />
                </div>
              </div>
            </div>
          </div>
          <hr className="text-white" />
          <div className="grid items-center grid-cols-12 gap-4 py-4">
            <div className="col-span-12 md:col-span-4">
              <div className="flex flex-col items-center justify-center w-full h-full text-center">
                <Image priority={false} className="md:mx-auto md:w-20 md:h-10"  width={60} height={60} src={process.env.IMG_CDN+'img/svg/call.svg'} alt="Call Us" />
                <h3 className="w-full">USA:
                  <span><Link href="tel: +14699457679"> +14699457679</Link></span>
                </h3>
                <h3 className="w-full">India:
                  <span><Link href="tel: 124-4147595"> 124-4147595,</Link></span>
                  <span><Link href="tel: +919650213330"> +91-9650213330</Link></span>
                </h3>
              </div>
            </div>
            <div className="col-span-12 md:col-span-4">
              <div className="flex flex-col items-center justify-center w-full h-full text-center md:border-r md:border-l">
                <Image priority={false} className="md:mx-auto md:w-20 md:h-10"  width={60} height={60} src={process.env.IMG_CDN+'img/svg/mail.svg'} alt="Mail Us" />
                <h3 className="w-full">Email:
                  <span><Link href="mailto:info@delveinsight.com"> info@delveinsight.com</Link></span>
                </h3>
              </div>
            </div>
            <div className="col-span-12 md:col-span-4">
              <div className="flex flex-col items-center justify-center w-full h-full text-center">
                <Image priority={false} className="md:mx-auto md:w-20 md:h-10"  width={60} height={60} src={process.env.IMG_CDN+'img/svg/navigation.svg'} alt="Our Location" />
                <div className="w-full">
                  <h3>USA: <span> 304 S. Jones Blvd #2432, Las Vegas NV 89107</span></h3>
                  <h3>India: <span> 63, M3M 113 Market, Sector 113, <br/> Gurugram, Haryana-122017, India</span></h3>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-white" />
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="">
              <p className="flex flex-wrap justify-center"><Link href="/">DelveInsight Business Research LLP </Link> <span>© 2025 </span></p>
            </div>
            <div className="flex flex-wrap justify-center">
              <Link className="px-2" href={process.env.APP_URL+"/terms-and-conditions"}>Terms &amp; Conditions</Link>|
              <Link className="px-2" href={process.env.APP_URL+"/privacy-policy"}>Privacy Policy</Link>|
              <Link className="px-2" href={process.env.APP_URL+"/refund-policy"}>Refund Policy</Link>|
              <Link className="px-2" href={process.env.APP_URL+"/cancellation-policy"}>Cancellation Policy</Link>
            </div>
            <div className="py-4 lg:py-0">
              <div className="flex">
                <Link target="_blank" href="https://in.linkedin.com/company/delveinsight-business-research-llp" aria-label="linkedin">
                  <i className="px-2 fa-brands fa-linkedin"></i>
                </Link>
                <Link target="_blank" href="https://twitter.com/delve_insight" aria-label="twitter">
                  <i className="px-2 fa-brands fa-twitter"></i>
                </Link>
                <Link target="_blank" href="https://www.facebook.com/delveinsightindia/" aria-label="facebook">
                  <i className="px-2 fa-brands fa-facebook"></i>
                </Link>
                <Link target="_blank" href="https://in.pinterest.com/search/pins/?q=delveinsight&amp;rs=typed&amp;term_meta[]=delveinsight%7Ctyped" aria-label="pinterest">
                  <i className="px-2 fa-brands fa-pinterest"></i>
                </Link>
                <Link target="_blank" href="https://www.instagram.com/delveinsight/" aria-label="instagram">
                  <i className="px-2 fa-brands fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  );
}

export default Footer;
